import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from '@Services/api.service';
import { UtilityService } from '@Services/utility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentService as DS } from '@Services/document.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService extends DS {
  get baseURL() {
    return environment.apiBaseUrl + '/rest/';
  }
 
  documentRetrived = false;

  constructor(
    public apiService: ApiService,
    public utilityService: UtilityService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super(apiService, utilityService, router, route);
  }

  uploadBulkDocuments(files: File[]) {
    const formData: FormData = new FormData();
    for (const file of files) {
      formData.append('files', file, file.name);
    }
    return this.apiService.postFile(
      this.baseURL + 'document/bulk/upload',
      formData
    );
  }
}
